import React from "react";

import MfeErrorBoundaryIcon from "@/assets/MfeErrorBoundaryIcon";
import "./MfeErrorBoundary.css";

interface MfeErrorBoundaryProps {
  error: Error;
}

const MfeErrorBoundary: React.FC<MfeErrorBoundaryProps> = ({ error }) => {
  return (
    <div
      className="error-boundary-container"
      data-testid="error-boundary-container"
    >
      <div
        className="error-boundary-content"
        data-testid="error-boundary-content"
      >
        <MfeErrorBoundaryIcon />
        <h2 data-testid="error-boundary-title">
          {" "}
          Ops! A Critical error ocurred{" "}
        </h2>
        <span data-testid="error-boundary-message">
          Something went wrong. Please try refreshing the page and if the error
          persists, contact support.
        </span>
        {process.env.NODE_ENV === "development" && (
          <pre className="error-boundary-pre" data-testid="error-boundary-pre">
            <span>{error?.message}</span>
            <span>{error?.stack}</span>
          </pre>
        )}
      </div>
    </div>
  );
};

export default MfeErrorBoundary;
