const MfeErrorBoundaryIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={128}
    height={128}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <g opacity={0.3}>
        <mask
          id="b"
          width={58}
          height={58}
          x={67}
          y={21}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: "luminance",
          }}
        >
          <path
            fill="#fff"
            d="M95.658 78.89c15.815 0 28.636-12.82 28.636-28.636 0-15.815-12.821-28.636-28.636-28.636-15.816 0-28.636 12.82-28.636 28.636 0 15.816 12.82 28.637 28.636 28.637Z"
          />
        </mask>
        <g mask="url(#b)">
          <path
            fill="url(#c)"
            d="M95.658 78.89c15.815 0 28.636-12.82 28.636-28.636 0-15.815-12.821-28.636-28.636-28.636-15.816 0-28.636 12.82-28.636 28.636 0 15.816 12.82 28.637 28.636 28.637Z"
          />
        </g>
      </g>
      <g opacity={0.3}>
        <mask
          id="d"
          width={50}
          height={50}
          x={-2}
          y={61}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: "luminance",
          }}
        >
          <path
            fill="#fff"
            d="M23.026 110.18c13.423 0 24.305-10.883 24.305-24.306 0-13.424-10.882-24.306-24.306-24.306C9.603 61.568-1.28 72.45-1.28 85.874c0 13.424 10.882 24.306 24.306 24.306Z"
          />
        </mask>
        <g mask="url(#d)">
          <path
            fill="url(#e)"
            d="M23.026 110.18c13.423 0 24.305-10.883 24.305-24.306 0-13.424-10.882-24.306-24.306-24.306C9.603 61.568-1.28 72.45-1.28 85.874c0 13.424 10.882 24.306 24.306 24.306Z"
          />
        </g>
      </g>
      <mask
        id="f"
        width={127}
        height={107}
        x={0}
        y={3}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path
          fill="#fff"
          d="M104.832 67.705c0 23.125-18.745 41.877-41.877 41.877-23.133 0-41.878-18.745-41.878-41.877l-20.594.299L8.747 3.769h111.452l6.521 64.234-21.888-.298Z"
        />
      </mask>
      <g mask="url(#f)">
        <mask
          id="g"
          width={84}
          height={85}
          x={21}
          y={25}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: "luminance",
          }}
        >
          <path
            fill="#fff"
            d="M62.97 109.547c23.127 0 41.876-18.75 41.876-41.878S86.097 25.792 62.969 25.792c-23.128 0-41.877 18.75-41.877 41.877 0 23.129 18.749 41.878 41.877 41.878Z"
          />
        </mask>
        <g mask="url(#g)">
          <path
            fill="url(#h)"
            d="M62.97 109.547c23.127 0 41.876-18.75 41.876-41.878S86.097 25.792 62.969 25.792c-23.128 0-41.877 18.75-41.877 41.877 0 23.129 18.749 41.878 41.877 41.878Z"
          />
        </g>
      </g>
      <mask
        id="i"
        width={127}
        height={107}
        x={0}
        y={3}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path
          fill="#fff"
          d="M104.832 67.705c0 23.125-18.745 41.877-41.877 41.877-23.133 0-41.878-18.745-41.878-41.877l-20.594.299L8.747 3.769h111.452l6.521 64.234-21.888-.298Z"
        />
      </mask>
      <g mask="url(#i)">
        <path
          fill="#E5D770"
          d="m86.514 49.757 10.425-7.972a1.44 1.44 0 0 1 1.97.206l9.599 11.236c.548.64.434 1.614-.256 2.112l-11.59 8.306-10.148-13.889ZM40.846 49.757l-10.432-7.972a1.44 1.44 0 0 0-1.97.206l-9.6 11.236a1.445 1.445 0 0 0 .256 2.112L30.7 63.645l10.147-13.889Z"
        />
        <path
          fill="#8C7C1B"
          d="M96.569 63.645h-65.87l10.147-13.889h45.582l10.14 13.889Z"
        />
        <path fill="url(#j)" d="M96.569 63.645h-65.87v65.87h65.87v-65.87Z" />
        <path
          fill="#000"
          d="M72.697 19.356h-18.12a4.512 4.512 0 0 0-3.178 1.316L37.888 34.183a4.482 4.482 0 0 0-1.316 3.179V55.48c0 1.195.477 2.332 1.316 3.179l13.51 13.51a4.482 4.482 0 0 0 3.18 1.316h18.119a4.512 4.512 0 0 0 3.178-1.315L89.386 58.66a4.482 4.482 0 0 0 1.316-3.18V37.363a4.512 4.512 0 0 0-1.316-3.179l-13.51-13.511a4.525 4.525 0 0 0-3.18-1.316Z"
          opacity={0.16}
        />
        <path
          fill="#D85951"
          d="M73.18 15.986H54.094a4.73 4.73 0 0 0-3.35 1.386L36.502 31.61a4.73 4.73 0 0 0-1.387 3.35v19.093a4.73 4.73 0 0 0 1.387 3.349l14.236 14.236a4.73 4.73 0 0 0 3.35 1.387H73.18a4.73 4.73 0 0 0 3.35-1.387l14.236-14.236a4.73 4.73 0 0 0 1.386-3.35V34.959a4.73 4.73 0 0 0-1.386-3.35L76.529 17.373a4.73 4.73 0 0 0-3.349-1.386Z"
        />
        <path
          fill="#D85951"
          d="M72.376 18.39H54.897a4.31 4.31 0 0 0-3.065 1.272L38.791 32.697a4.351 4.351 0 0 0-1.273 3.065V53.24a4.31 4.31 0 0 0 1.273 3.065l13.041 13.042c.81.81 1.92 1.273 3.065 1.273h17.48a4.31 4.31 0 0 0 3.064-1.273l13.042-13.042a4.351 4.351 0 0 0 1.273-3.065V35.76a4.31 4.31 0 0 0-1.273-3.064L75.44 19.655a4.332 4.332 0 0 0-3.065-1.266Z"
        />
        <path
          fill="#fff"
          d="M61.475 58.873c-.569-.583-.853-1.323-.853-2.226s.285-1.636.853-2.204c.57-.57 1.302-.854 2.205-.854s1.643.285 2.226.854.874 1.301.874 2.204c0 .903-.291 1.643-.874 2.226-.584.583-1.323.875-2.226.875s-1.636-.292-2.205-.875Zm.57-9.5a.51.51 0 0 1-.15-.37l-.484-19.222c0-.142.05-.27.157-.37.1-.099.227-.149.37-.149h4.017a.51.51 0 0 1 .52.52l-.477 19.22c0 .15-.05.27-.156.37-.1.1-.228.157-.37.157h-3.058a.49.49 0 0 1-.37-.157Z"
        />
        <path
          fill="#fff"
          d="M72.376 20.28c.654 0 1.266.257 1.728.712l13.042 13.042c.462.462.718 1.074.718 1.728V53.24c0 .654-.256 1.266-.71 1.728L74.11 68.01a2.42 2.42 0 0 1-1.728.71H54.897a2.45 2.45 0 0 1-1.728-.71L40.127 54.969a2.426 2.426 0 0 1-.718-1.728V35.76c0-.653.256-1.265.711-1.727l13.042-13.042a2.42 2.42 0 0 1 1.728-.711h17.486Zm0-1.89H54.897a4.31 4.31 0 0 0-3.065 1.272L38.791 32.697a4.351 4.351 0 0 0-1.273 3.065V53.24a4.31 4.31 0 0 0 1.273 3.065l13.041 13.042c.81.81 1.92 1.273 3.065 1.273h17.48a4.31 4.31 0 0 0 3.064-1.273l13.042-13.042a4.351 4.351 0 0 0 1.273-3.065V35.76a4.31 4.31 0 0 0-1.273-3.064L75.44 19.655a4.332 4.332 0 0 0-3.065-1.266Z"
        />
        <path
          fill="#E5D770"
          d="M30.791 63.645H96.62l3.57 12.607c.554 1.956-.946 3.89-3.008 3.89H30.237c-2.063 0-3.563-1.934-3.008-3.89l3.562-12.607Z"
        />
        <path
          fill="#000"
          d="M96.562 80.15H30.734v3.462h65.828V80.15Z"
          opacity={0.16}
        />
      </g>
      <path
        fill="#000"
        d="M84.729 61.568H42.596l2.005 2.076h38.002l2.126-2.076Z"
        opacity={0.16}
      />
      <g opacity={0.5}>
        <mask
          id="k"
          width={15}
          height={15}
          x={11}
          y={36}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: "luminance",
          }}
        >
          <path
            fill="#fff"
            d="M18.531 50.916a7.218 7.218 0 1 0 0-14.436 7.218 7.218 0 0 0 0 14.436Z"
          />
        </mask>
        <g mask="url(#k)">
          <path
            fill="url(#l)"
            d="M18.531 50.916a7.218 7.218 0 1 0 0-14.436 7.218 7.218 0 0 0 0 14.436Z"
          />
        </g>
      </g>
      <g opacity={0.5}>
        <mask
          id="m"
          width={16}
          height={15}
          x={101}
          y={97}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: "luminance",
          }}
        >
          <path
            fill="#fff"
            d="M109.056 111.879a7.217 7.217 0 0 0 7.217-7.218 7.217 7.217 0 1 0-14.435 0 7.217 7.217 0 0 0 7.218 7.218Z"
          />
        </mask>
        <g mask="url(#m)">
          <path
            fill="url(#n)"
            d="M109.056 111.879a7.217 7.217 0 0 0 7.217-7.218 7.217 7.217 0 1 0-14.435 0 7.217 7.217 0 0 0 7.218 7.218Z"
          />
        </g>
      </g>
    </g>
    <defs>
      <linearGradient
        id="c"
        x1={67.02}
        x2={124.298}
        y1={50.252}
        y2={50.252}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DFE0E4" />
        <stop offset={1} stopColor="#EEEFF1" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={-1.28}
        x2={47.335}
        y1={85.872}
        y2={85.872}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DFE0E4" />
        <stop offset={1} stopColor="#EEEFF1" />
      </linearGradient>
      <linearGradient
        id="h"
        x1={21.097}
        x2={104.846}
        y1={67.669}
        y2={67.669}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DFE0E4" />
        <stop offset={1} stopColor="#EEEFF1" />
      </linearGradient>
      <linearGradient
        id="j"
        x1={63.635}
        x2={63.635}
        y1={129.52}
        y2={63.647}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D8AF32" />
        <stop offset={1} stopColor="#D8BC32" />
      </linearGradient>
      <linearGradient
        id="l"
        x1={11.312}
        x2={25.752}
        y1={43.696}
        y2={43.696}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DFE0E4" />
        <stop offset={1} stopColor="#EEEFF1" />
      </linearGradient>
      <linearGradient
        id="n"
        x1={101.834}
        x2={116.273}
        y1={104.664}
        y2={104.664}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DFE0E4" />
        <stop offset={1} stopColor="#EEEFF1" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h128v128H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default MfeErrorBoundaryIcon;
