import React from "react";

import "./LocalSkeletonLoading.css";

export interface SkeletonLoaderProps
  extends React.HTMLAttributes<HTMLDivElement> {
  variant?: "body" | "image";
  imageShape?: "rectangle" | "circle";
  width?: string;
  height?: string;
}

export default function LocalSkeletonLoading({
  height = "56px",
  width = "100%",
  imageShape = "rectangle",
  variant = "body",
  ...divProps
}: SkeletonLoaderProps) {
  const containerClasses = [
    "skeleton-container",
    variant === "image" ? "image" : "",
    variant === "image" && imageShape === "circle" ? "circle" : "",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div
      className={containerClasses}
      data-testid="skeleton-container"
      style={
        {
          "--width": width,
          "--height": height,
          "--border-radius":
            variant === "image" && imageShape === "circle" ? "50%" : "4px",
          "--background-color":
            variant === "image" ? "none" : "rgba(0, 0, 0, 0.05)",
        } as React.CSSProperties
      }
      tabIndex={-1}
      {...divProps}
    >
      {variant === "image" && (
        <img
          className="skeleton-image"
          src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iODBweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgODAgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+VXNlckZlZWRiYWNrL1NrZWxldG9uL0ltYWdlL1JlY3RhbmdsZS80OjMgQ29weSAyPC90aXRsZT4KICAgIDxkZWZzPgogICAgICAgIDxyZWN0IGlkPSJwYXRoLTEiIHg9IjAiIHk9IjAiIHdpZHRoPSI4MCIgaGVpZ2h0PSI2MCIgcng9IjQiPjwvcmVjdD4KICAgIDwvZGVmcz4KICAgIDxnIGlkPSLinIUtU2tlbGV0b24tTG9hZGVyIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iU2tlbGV0b24tTG9hZGVyLi1Vc2FnZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTY5MS4wMDAwMDAsIC0xMjkwLjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDY5MS4wMDAwMDAsIDEyOTAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8bWFzayBpZD0ibWFzay0yIiBmaWxsPSJ3aGl0ZSI+CiAgICAgICAgICAgICAgICAgICAgPHVzZSB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4KICAgICAgICAgICAgICAgIDwvbWFzaz4KICAgICAgICAgICAgICAgIDx1c2UgaWQ9Ik1hc2siIGZpbGwtb3BhY2l0eT0iMC4wNSIgZmlsbD0iIzAwMDAwMCIgeGxpbms6aHJlZj0iI3BhdGgtMSI+PC91c2U+CiAgICAgICAgICAgICAgICA8ZyBtYXNrPSJ1cmwoI21hc2stMikiIGZpbGw9IiMwMDAwMDAiIGZpbGwtb3BhY2l0eT0iMC4wNSI+CiAgICAgICAgICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsIDcuMTA3Nzg0KSI+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoLTIiIGZpbGwtcnVsZT0iZXZlbm9kZCIgcG9pbnRzPSIwIDM2LjA3NTg4MzYgMTkuNzY1MDA2NSAyMi41NjE2OTAzIDM2LjU2MDUwOTYgMzUuMTIyMzgwMiA2MS4wMTkxMDgzIDE3LjM1MjU0NDkgODAgMzAuMzU0ODYzNCA4MCA1Mi44OTIyMTU2IDAgNTIuODkyMjE1NiI+PC9wb2x5Z29uPgogICAgICAgICAgICAgICAgICAgICAgICA8Y2lyY2xlIGlkPSJPdmFsIiBmaWxsLXJ1bGU9Im5vbnplcm8iIGN4PSIzMCIgY3k9IjgiIHI9IjgiPjwvY2lyY2xlPgogICAgICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
          alt="skeleton loader"
        />
      )}
    </div>
  );
}
