/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import ReactDOM from "react-dom";

import { useLogService } from "admin-portal-shared-services";
import singleSpaReact from "single-spa-react";

import App from "./App";
import MfeErrorBoundary from "./components/mfeErrorBoundary/MfeErrorBoundary";

const reactLifeCycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(error: Error) {
    useLogService().error(error);

    return <MfeErrorBoundary error={error} />;
  },
});

export const { bootstrap, mount, unmount } = reactLifeCycles;
