import React from "react";

import "./MfeLoading.css";
import LocalSkeletonLoading from "../localSkeletonLoading/LocalSkeletonLoading";

export default function MfeLoading() {
  return (
    <div className="mfe-loading-container" data-testid="mfe-loading-container">
      <LocalSkeletonLoading height="40px" width="157px" />
      <LocalSkeletonLoading height="60vh" width="100%" />
    </div>
  );
}
